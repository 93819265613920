import { getCookie, setCookie } from '@/js/utils/cookie';
import { deleteStored } from '@/js/utils/session-storage';
import { v4 as uuid } from 'uuid';

/*
   When beginning cart flow on LPs (ex: Resume Quote), tracking IDs
   need to be generated to ensure proper eventing
 */
export const getClientSideTrackingIds = () => {
  // Check if tracking IDs already exist
  const clientSideTrackingIds = getCookie( 'clientSideTrackingIds' );
  if ( clientSideTrackingIds ) return JSON.parse( clientSideTrackingIds );

  // Create new IDs if they don't exist
  const clientSessionId = uuid();
  const orderId = uuid();
  const correlationId = uuid();

  // Wipe out persisted cart state if starting a new session
  deleteStored( 'persist:fuel' );

  const trackingIds = { clientSessionId, orderId, correlationId };
  setCookie( 'clientSideTrackingIds', JSON.stringify( trackingIds ), 60 ); // Cookie expires in 60 minutes
  return trackingIds;
};

/*
   When user enters LP via cart with persisted serviceability,
   we may need to strip /plan-package off of the clickThrough
   to send them to a different page (ex. /my-custom-quote)
 */
export const stripClickThrough = ( url ) => {
  const stripped = url.replace( '/plan-package', '' );
  return stripped;
};
