/**
 * @module ResumeQuoteHooks
 * @description
 */
import { useContext, useState } from 'react';
import { FuelContext } from '@/providers/fuel-provider';
import { getCookie } from '@/js/utils/cookie';
import { getClientSideTrackingIds } from '@/js/helpers/cart';
import { persistData } from '@/js/utils/session-storage';
import { trackEvent } from '@/js/helpers/tracking';
import { WRITE_KEY } from '@/js/constants/cohesion';

/**
 * @function useResumeQuote
 * @description
 */
export const useResumeQuote = ( setModalVisible ) => {
  const { clickThrough, olVersion } = useContext( FuelContext );
  const [ responseLoading, setResponseLoading ] = useState( true );
  const [ response, setResponse ] = useState( null );

  const getResumeResponse = async () => {
    const quoteId = getCookie( 'quoteId' );

    if ( ! quoteId ) return;

    try {
      const { clientSessionId } = getClientSideTrackingIds();

      const resumeResponse = await fetch(
        `${ process.env.predictiveSearchUrl }/api/${ olVersion }/quote/resume`,
        {
          method: 'get',
          headers: {
            quoteId,
            'x-write-key': WRITE_KEY,
            'x-client-session-id': clientSessionId,
            'x-client-id': `fuel-cart-${ process.env.environment }-frdc`
          }
        }
      );

      if ( ! resumeResponse || ! resumeResponse?.ok ) {
        setResponseLoading( false );
        setModalVisible( false );

        return;
      }

      const data = await resumeResponse?.json();

      setResponse( data );
      setResponseLoading( false );

      const eventData = {
        elementType: 'ADDRESS LOADED',
        position: 'RESUME QUOTE MODAL'
      };

      trackEvent( { action: 'elementViewed', data: eventData, event } );
    } catch ( error ) {
      console.error( error );

      // Hide modal if resume request fails
      setModalVisible( false );
    }
  };

  const continueCheckout = () => {
    persistData( 'resumeResponse', JSON.stringify( response ) ); // Store response to be used in cart

    window.location.href = `${ clickThrough.replace( '/plan-package', '' ) }/resume?resumeQuote`;
  };

  const formatAddress = () => {
    const {
      address, address2, zip
    } = response?.serviceAddress;

    const formatted = `${ address }${ address2 && ` ${ address2 }` }, ${ zip }`;

    // If an address doesn't have one of the variables above, we need to strip out the double space
    return formatted.replace( /\s\s/, ' ' );
  };

  return {
    responseLoading,
    continueCheckout,
    getResumeResponse,
    formatAddress
  };
};
