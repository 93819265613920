/**
 * @module Loader
 * @description Spinning loader animation from https://loading.io/css/
 */

import styles from './index.module.css';

export const Component = () => (
  <div className={ styles.loader }>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);

export default Component;
