/**
 * @module Home - Hero - Address Check - Gig
 * @description
 */
import HeroAddressCheck from '@/blocks/Heroes/HeroAddressCheck';

import styles from './HeroAddressCheck5G.module.css';

/**
 * @function Component
 * @description
 */
const Component = () => (
  <HeroAddressCheck
    customStyles={ styles }
    horizonHeadline={ [
      <>Fiber&nbsp;<span className="u-color-blue">5&nbsp;gig</span></>,
      'internet',
      <>is&nbsp;here</>
    ] }
    background="young-adults-gaming"
    useSelectAreasLegal={ <>Frontier<sup>&reg;</sup> Fiber Internet available in select&nbsp;areas.</> }
  />
);

export default Component;
