/**
 * @module Split Hero - Price Point
 * @description Price Point Hero
 */
import SplitSection from '@/blocks/SplitSection';
import Tooltip from '@/blocks/Tooltip';

/**
 * @function Component
 * @description
 */
const Component = () => (
  <SplitSection
    title={ <>Save $120 on YouTube&nbsp;TV</> }
    showItems={ false }
    content={ <>Get your favorite channels with Frontier<sup>&reg;</sup> Fiber and YouTube TV. Save $10/mo. on your first year of YouTube TV. Watch 100+ channels of live sports, news and shows on our ultra-fast fiber internet.<span data-legal="youtubetv-condensed" /><Tooltip disclosure="tt-yttv" /></> }
    image={ {
      url: '/sections/split-section/youtubetv-ui-electric-blue.jpg',
      blurUrl: '/sections/split-section/youtubetv-ui-electric-blue.jpg',
      alt: 'Television with YouTube TV Logo'
    } }
    dualCTA="/shop/tv"
    reverseLayout
    reverseMobile
    theme="blue"
  />
);

export default Component;
