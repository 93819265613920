/**
 * @module ResumeQuoteModal
 * @description Resume Quote Modal
 */
import { useEffect, useState } from 'react';
import { useResumeQuote } from '@/hooks/useResumeQuote';
import MicroModal from 'micromodal';
import Loader from '@/blocks/Loader';
import { trackEvent } from '@/js/helpers/tracking';
import PropTypes from 'prop-types';
import styles from './index.module.css';
import { isStored, persistData } from '@/js/utils/session-storage';

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    title,
    subtitle
  } = props;

  const [ modalVisible, setModalVisible ] = useState( false );
  const {
    responseLoading, getResumeResponse, formatAddress, continueCheckout
  } = useResumeQuote( setModalVisible );

  /**
   * @function handleClose
   * @description handle modal close
   */
  const handleClose = () => {
    const data = {
      elementType: 'BUTTON',
      position: 'RESUME QUOTE MODAL',
      text: 'CLOSE',
      actionOutcome: 'INTERNALLINK'
    };

    trackEvent( { action: 'elementClicked', data, event } );

    // Indicate to cart that the user declined to resume, so that they aren't asked again
    persistData( 'declinedResume', '1' );

    setModalVisible( false );
  };

  /**
   * @function useEffect
   * @description initialize the modal
   */
  useEffect( () => {
    if ( modalVisible === false ) {
      return;
    }

    MicroModal.init( {
      openClass: 'is-open',
      disableScroll: true,
      disableFocus: true,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: false
    } );
    MicroModal.show( 'modal-resume-quote', { onClose: handleClose } );

    const data = {
      elementType: 'MODAL',
      position: 'RESUME QUOTE MODAL'
    };

    trackEvent( { action: 'elementViewed', data, event } );
  }, [ modalVisible ] );

  // Ensure modal is only shown once per session
  useEffect( () => {
    if ( isStored( 'seenResumeModal' ) ) return;

    getResumeResponse();
    setModalVisible( true );
    persistData( 'seenResumeModal', '1' );
  }, [ getResumeResponse ] );

  return <>
    { modalVisible && <section className={ `component ${ styles.component }` }>
      <div className="modal micromodal-slide" id="modal-resume-quote" aria-hidden="true">
        <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
          <div className={ `modal__container ${ styles.component__modalContainer }` } role="dialog" aria-modal="true" aria-labelledby="modal-resume-quote-title">
            <button className={ `modal__close ${ styles.component__close }` } aria-label="Close modal" data-micromodal-close></button>
            <div className={ styles.component__contentWrapper }>
              <div className={ styles.component__header }>
                <p className={ `h4 ${ styles.component__title }` } id="modal-resume-quote-title">{ title }</p>
                <p className={ styles.component__subtitle }>{ subtitle }</p>
              </div>{ /* <!-- .modal__header --> */ }

              <div className={ styles.component__body }>
                <div className={ styles.component__addressWrapper }>
                  <span className={ `icon icon-location-marker2 ${ styles.component__icon }` } />
                  <span>Service Address</span>
                  <span className={ styles.component__address }>{ responseLoading ? <Loader /> : formatAddress() }</span>
                </div>
                <div className={ styles.component__buttonWrapper }>
                  <button
                    type="submit"
                    className="btn"
                    disabled={ responseLoading }
                    aria-disabled={ responseLoading }
                    role="button"
                    onClick={ continueCheckout }
                  >
                    CONTINUE CHECKOUT
                  </button>
                </div>
              </div>{ /* <!-- .modal__content --> */ }
            </div>{ /* <!-- .component__contentWrapper --> */ }
          </div>{ /* <!-- .modal__container --> */ }
        </div>{ /* <!-- .modal__overlay --> */ }
      </div>{ /* <!-- .modal --> */ }
    </section> }
  </>;
};

Component.defaultProps = {
  title: 'Welcome back. Let\'s finish your order.',
  subtitle: 'We saved your information so you can pick up where you left off.'
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default Component;
