/**
 * @module Return Visitor Modal
 * @description Return Visitor Modal
 */

import { useRouter } from 'next/router';
import { useEffect, useContext, useState } from 'react';
import MicroModal from 'micromodal';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { setCookie, getCookie } from '@/js/utils/cookie';
import { stringify } from '@/js/utils/filters';
import { FuelContext } from '@/providers/fuel-provider';
import { trackEvent } from '@/js/helpers/tracking';

import styles from './index.module.css';
import { CohesionContext } from '@red-digital/bricks';
import { formatPhoneNumber } from '@/js/helpers/fuse';

const getDefaultBlocks = ( phoneNumber ) => ( [
  {
    title: 'Shop',
    content: 'Order now and get free expert installation & a premium Wi-Fi router included with all fiber plans.',
    link: {
      url: null,
      title: 'Shop Plans',
      payload: {
        text: 'Shop Plans',
        actionOutcome: 'INTERNALLINK'
      }
    }
  },
  {
    title: 'Learn',
    content: 'Upgrade to Frontier Fiber and we\'ll include a Wi-Fi router at no additional cost.',
    link: {
      url: '/shop',
      title: 'Learn more',
      payload: {
        text: 'Learn more',
        actionOutcome: 'INTERNALLINK'
      }
    }
  },
  {
    title: 'Call',
    content: 'Our professionals will find the right internet plan for you.',
    link: {
      url: 'tel:#',
      title: formatPhoneNumber( phoneNumber ),
      payload: {
        text: 'Phone Number',
        actionOutcome: 'CLICKTOCALL'
      }
    },
    fuse: true
  }
] );

/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const { fuseNumber, defaultFuseNumber } = useContext( CohesionContext );
  const {
    title, subtitle, blocks = getDefaultBlocks( fuseNumber || defaultFuseNumber )
  } = props;
  const router = useRouter();
  const [ modalVisible, setModalVisible ] = useState( false );

  const { clickThrough } = useContext( FuelContext );

  /**
   * @function handleClick
   * @description fire off tagular events
   */
  const handleClick = ( event ) => {
    const { target } = event;
    const payload = JSON.parse( target.getAttribute( 'data-payload' ) );
    const { text, actionOutcome } = payload;

    const data = {
      elementType: 'BUTTON',
      position: 'MODAL',
      text,
      actionOutcome
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  /**
   * @function handleClose
   * @description handle modal close
   */
  const handleClose = () => {
    const data = {
      elementType: 'BUTTON',
      position: 'MODAL',
      text: 'Close',
      actionOutcome: 'INTERNALLINK'
    };

    trackEvent( { action: 'elementClicked', data, event } );

    setModalVisible( false );
  };

  /**
   * @function useEffect
   * @description initialize the modal
   */
  useEffect( () => {
    if ( modalVisible === false ) {
      return;
    }

    MicroModal.init( {
      openClass: 'is-open',
      disableScroll: true,
      awaitOpenAnimation: false,
      awaitCloseAnimation: false,
      debugMode: false
    } );
    MicroModal.show( 'modal-return-visitor', { onClose: handleClose, disableFocus: true } );

    const data = {
      elementType: 'MODAL',
      position: 'RETURN VISITOR MODAL'
    };

    trackEvent( { action: 'elementViewed', data, event } );
  }, [ modalVisible ] );

  /**
   * @function useEffect
   * @description Query param to trigger manually
   */
  useEffect( () => {
    if ( 'showModal' in router.query ) {
      setModalVisible( true );
    }
  }, [ router.query ] );

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    // Bail if user has already seen the modal.
    if ( getCookie( 'hasSeenModal' ) === '1' ) {
      return;
    }

    // New user shouldn't see modal. Set cookie with value of false.
    if ( typeof getCookie( 'hasSeenModal' ) === 'undefined' || getCookie( 'hasSeenModal' ) === 'undefined' ) {
      setCookie( 'hasSeenModal', 0, 10080 );

    // If the value is now 0, we've seen this user, let's show em a modal.
    } else if ( getCookie( 'hasSeenModal' ) === '0' ) {
      setModalVisible( true );
      setCookie( 'hasSeenModal', '1', 10080 ); // Set cookie for 7 days
    }
  }, [ ] );

  return <>
    { modalVisible && <section className={ `component ${ styles.component }` }>
      <div className="modal micromodal-slide" id="modal-return-visitor" aria-hidden="true">
        <div className="modal__overlay" tabIndex="-1" data-micromodal-close>
          <div className={ `modal__container ${ styles.component__modalContainer }` } role="dialog" aria-modal="true" aria-labelledby="modal-return-visitor-title">
            <button className={ `modal__close ${ styles.component__close }` } aria-label="Close modal" data-micromodal-close></button>
            <div className={ styles.component__contentWrapper }>
              <div className={ `modal__header ${ styles.component__header }` }>
                <p className={ `h4 ${ styles.component__title }` } id="modal-return-visitor-title">{ title }</p>
                <p className={ styles.component__subtitle }>{ subtitle }</p>
              </div>{ /* <!-- .modal__header --> */ }

              <div className={ `modal__content ${ styles.component__body }` } id="modal-return-visitor-content">
                <div className={ styles.component__blocks }>
                  { blocks.map( ( block, index ) => (
                      <div key={ index } className={ styles.component__block }>
                        <h3 className={ `h5 ${ styles.component__blockTitle }` }>{ block.title }</h3>
                        <div className={ `lead ${ styles.component__blockContent }` }>{ block.content }</div>
                        <Link
                          href={ block.link.url || clickThrough }
                          className={ `btn btn--red btn-small ${ styles.component__blockBtn }` }
                          data-payload={ stringify( block.link.payload ) }
                          onClick={ handleClick }>{ block.link.title }
                        </Link>
                      </div>
                  ) ) } { /* <!-- .modal__block --> */ }

                </div>{ /* <!-- .component__block --> */ }
              </div>{ /* <!-- .modal__content --> */ }
            </div>{ /* <!-- .component__contentWrapper --> */ }
          </div>{ /* <!-- .modal__container --> */ }
        </div>{ /* <!-- .modal__overlay --> */ }
      </div>{ /* <!-- .modal --> */ }
    </section> }
  </>;
};

Component.defaultProps = {
  title: "We're glad you're back!",
  subtitle: <><span className="u-color-red">New Offer:</span> Save $10 on your monthly bill when you sign up for Auto Pay, with installation as early as tomorrow.</>
};

Component.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.node,
  blocks: PropTypes.arrayOf(
    PropTypes.shape( {
      icon: PropTypes.string,
      title: PropTypes.string,
      content: PropTypes.node,
      link: PropTypes.shape( {
        url: PropTypes.string,
        title: PropTypes.string,
        payload: PropTypes.shape( {
          text: PropTypes.string,
          actionOutcome: PropTypes.string
        } )
      } ),
      fuse: PropTypes.bool
    } )
  )
};

export default Component;
