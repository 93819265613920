
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults.js");
/**
 * @module Home
 * @description Home Page
 */
import { lazy, Suspense, useContext } from 'react';
import Head from 'next/head';
import { Preamp } from '@red-digital/bricks';
import Template from '@/templates/Default';
import ReturnVisitorModal from '@/blocks/Modals/ReturnVisitorModal';
import ResumeQuoteModal from '@/blocks/Modals/ResumeQuoteModal';
import PreampFallback from '@/blocks/PreampFallback';

// Temporary Preamp Assets Moved to Base Site
import Hero5G from '@/blocks/Heroes/AddressCheck5G/HeroAddressCheck5G';
import SplitSectionYTTV from '@/blocks/SplitSectionYTTV';
const CarouselP72 = lazy( () => import( '@/blocks/Carousel P72 Lite' ) );
import FloatingPanel from '@/blocks/FloatingPanel';
const ColumnContentP72 = lazy( () => import( '@/blocks/ColumnContent/ColumnContentP72' ) );
import { FuelContext } from '@/providers/fuel-provider';

/**
 * @function Page
 * @description Page
 */
const Page = () => {
  const { resumeQuote, phoneHeavy } = useContext( FuelContext );

  return (
    <>
      <Head>
        <title>Fiber Internet, TV & Phone Service Provider | Frontier Internet</title>
        <meta
          name="description"
          content="Get high-speed internet with Frontier! Explore fiber optic internet plans & bundle with TV & phone for incredible value. Ultrafast fiber internet. Shop Now!"
        />
        <link rel="canonical" href="https://frontier.com/" />
      </Head>

      <Template
        title="Frontier Fiber Internet Service Provider"
        modal={
          resumeQuote && ! phoneHeavy ? (
            <ResumeQuoteModal />
          ) : (
            <Preamp placement="5Of7LlccpzT6qpBB91VQa0">
              <ReturnVisitorModal />
            </Preamp>
          )
        }
        hero={
          <>
            {/* Home | Banner */}
            <Preamp placement="5Sw7U4k5F4obLWEHDMWOYA" />

            {/* Home | Hero */}
            <Preamp
              placement="4mCzMVI47TXrDYCRW0Dk6y"
              skeleton={ () => (
                <PreampFallback height={ [ '533px', '323px', '431px' ] } />
              ) }
            >
              <Hero5G />
            </Preamp>
          </>
        }
        primary={
          <>
            {/* Home | Section 1 */}
            <Preamp
              placement="6bDLCm0rNdPBPCbYQfycna"
              skeleton={ () => (
                <PreampFallback height={ [ '309px', '309px', '323px' ] } />
              ) }
            >
              <SplitSectionYTTV />
            </Preamp>

            {/* Home | Section 2 */}
            <Preamp
              placement="4g7IPuHwin8Uo4slIi3Fkq"
              skeleton={ () => (
                <PreampFallback height={ [ '738px', '600px', '620px' ] } />
              ) }
            >
              <Suspense>
                <CarouselP72 />
              </Suspense>
            </Preamp>

            {/* Home | Section 3 */}
            <Preamp
              placement="7o1SamkWTKAXgnMmfebTrI"
              skeleton={ () => (
                <PreampFallback height={ [ '738px', '420px', '420px' ] } />
              ) }
            >
              <FloatingPanel />
            </Preamp>

            {/* Home | Section 4 */}
            <Preamp
              placement="5zv3XAD18siayIs70Kzvi5"
              skeleton={ () => (
                <PreampFallback height={ [ '738px', '596px', '527px' ] } />
              ) }
            >
              <Suspense>
                <ColumnContentP72 />
              </Suspense>
            </Preamp>

            {/* Home | Section 5 */}
            <Preamp
              placement="2JrI7VXRG8ddH15Rwk1CEv"
              skeleton={ () => <PreampFallback height={ [ '0px', '0px', '0px' ] } /> }
            />

            {/* Home | Section 6 */}
            <Preamp placement="5hGCO98G1o8NKRu39A22ZG" />

            {/* Home | Section 7 */}
            <Preamp placement="6sUqUDYpPzoORwfL6jGnIa" />

            {/* Home | Section 8 */}
            <Preamp placement="7wI1O9qQ9Vh43DO0lHizcy" />

            {/* Home | Sticky CTA */}
            <Preamp placement="7kPU2fJ4O1j9USiPa1Brrt" />
          </>
        }
      ></Template>
    </>
  );
};

export default Page;
